import { makeAutoObservable, runInAction } from 'mobx';
import { ICoordinates } from "core/types";
import { actionTypes, ModalType } from "core/modals";
import { modalStore } from "./";
import { Viewport } from "@urbica/react-map-gl/src/components/MapGL";
import { mapActionClickType } from "components/Modals/CreateMarkerModal/CreateMarkerModal";

export interface MapServiceProps extends ICoordinates {
    lngLat: ICoordinates
}

export class MapStore {
    mapProps: MapServiceProps = {
        lng: 25,
        lat: 42,
        lngLat: {
            lng: 25,
            lat: 42,
        },
    };
    zoom: number = 2.5;
    markerCoordinates: [number, number] | undefined = undefined;

    constructor() {
        makeAutoObservable(this)
        this.onMapMove = this.onMapMove.bind(this);
        this.onMapMouseMove = this.onMapMouseMove.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
    }

    public setMarkerCoordinates(markerCoordinates: [number, number] | undefined) {
        runInAction(() => this.markerCoordinates = markerCoordinates);
    }

    public onMapMove(viewport: Viewport) {
        runInAction(() => {
            this.mapProps.lng = viewport.longitude;
            this.mapProps.lat = viewport.latitude;
            this.zoom = Number(viewport.zoom.toFixed(1));
        });
    }

    public onMapMouseMove(event: any) {
        runInAction(() => {
            this.mapProps.lngLat = event.lngLat.wrap();
        });
    }

    public onMapClick({onClose, actionType}: {
        onClose: () => void,
        actionType: actionTypes
    }) {
        if (actionType === mapActionClickType.CREATE_MARKER) {
            return modalStore.open({
                type: ModalType.CreateMarker,
                onClose: onClose,
                actionType: actionType,
            });
        }
    }
}

export const mapStore = new MapStore();
