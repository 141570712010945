import moment from "moment/moment";
import { markersService, notificationService } from "services";
import { IFeatures } from "stores/AreaStore";
import { EVENTS_TYPES, UNIT_TYPES } from "utils";
import { Nullable } from "primereact/ts-helpers";
import { TableType } from "core/tables";
import { IMarker } from "core/types";
import { IDrawProps } from "core/draws";
import { mapStore, markersStore, notificationStore, tableStore } from "stores";
import { drawService } from "services/DrawService";

export const EVENT_TYPE_TITLE = "Events"

export class MapGlContainerController {

    getEventsMarkerTypeId() {
        return tableStore.markerTypesData.find(type => type.title === EVENT_TYPE_TITLE)?.id
    }

    getTypeIds(eventsMarkerTypeId: number | undefined) {
        return tableStore.markerTypesData.map(markerType => markerType.id).filter(id => id !== eventsMarkerTypeId)
    }

    async onMapLoaded(e: { target: any, type: string }) {

        async function loadImage(target: any, path: string, type: string, isSdf: boolean) {
            if (!target.hasImage(type)) {
                await target.loadImage(path, (error: any, image: any) => {
                    if (error) throw error;
                    target.addImage(type, image, {sdf: isSdf});
                });
            }
        }

        await loadImage(e.target, `arrowDirection.png`, 'arrowDirection', true);

        UNIT_TYPES.map(async (unit) => {
            if (unit.hasImage) await loadImage(e.target, `units/${unit.type}.png`, unit.type, false);
        })
        EVENTS_TYPES.map(async (event) => {
            if (event.hasImage) await loadImage(e.target, `events/${event.type}.png`, event.type, false);
        })
    }

    async getEvents(dates: Nullable< Date[]>, eventsMarkerTypeId: number | undefined) {
        if (!dates || !eventsMarkerTypeId) return;

        const formattedDates = dates.map((date, index) => {
            if (index === 0) return moment(date).startOf('day').toISOString()
            if (index === 1) return moment(date).endOf('day').toISOString()
            return moment(date).startOf('day').toISOString()
        })
        const [createdFrom, createdTo] = formattedDates

        return await markersService.getEvents({
            typeIds: [eventsMarkerTypeId],
            dates: {
                createdFrom: createdFrom,
                createdTo: createdTo,
            }
        });
    }

    getMarkersByTypeIds(typeIds: number[]) {
        const {markers} = markersStore;
        if (typeIds && typeIds.length > 0 && markers.length === 0) markersService.init(typeIds);
    }

    handleOnMouseMove(event: any) {
        mapStore.onMapMouseMove(event)
    }

    async updateMarker(markerData: IMarker) {
        const {mapProps} = mapStore;
        markersStore.removeMarkerFromStore([markerData.id])
        markersService.updateMarkerAPIRequest({
            id: markerData.id,
            title: markerData.title,
            type: markerData.type,
            country: markerData.country,
            desc: markerData.desc,
            icon: markerData.icon,
            image: markerData.image,
            videoUrl: markerData.videoUrl,
            coordinates: {
                lat: mapProps.lngLat.lat,
                lng: mapProps.lngLat.lng
            },
        }).then(res => {
            if (res) {
                markersStore.addMarkerToStore({
                    ...res.data,
                });
                notificationStore.push(notificationService.create({
                    data: res.data,
                    dataType: TableType.MarkersTable,
                    type: 'success',
                    actionType: 'update'
                }));
            }
        });
    }

    async copyMarkerToCoordinates(markerData: IMarker) {
        const {mapProps} = mapStore;
        await markersService.createMarkerAPIRequest({
            title: markerData.title,
            type: markerData.type,
            country: markerData.country,
            desc: markerData.desc,
            icon: markerData.icon,
            image: markerData.image,
            videoUrl: markerData.videoUrl,
            coordinates: {
                lat: mapProps.lngLat.lat,
                lng: mapProps.lngLat.lng
            },
        })
    }

    public setDrawData(features: IFeatures) {
        drawService.setDrawData(features)
    }

    public setDrawProps(drawProps: IDrawProps) {
        drawService.setDrawProps(drawProps)
    }

    public setDrawMode(drawMode: string) {
        drawService.setDrawMode(drawMode)
    }

    public setMarkerCoordinates(coordinates: [number, number] | undefined) {
        mapStore.setMarkerCoordinates(coordinates)
    }
}

export const mapGlContainerController = new MapGlContainerController();
