import { IEventType } from "core/types";

import {
    AirDefenseComponent,
    ArrestComponent,
    AttentionComponent, AuthorityComponent,
    BusComponent,
    CampComponent,
    CarComponent,
    CasualtiesComponent,
    ChemicalComponent,
    DroneComponent,
    ElectricityComponent,
    ExplosionComponent,
    FightComponent,
    FireComponent,
    FlagComponent, HealthComponent,
    ManPadsComponent,
    MicrophoneComponent,
    MinesComponent,
    MissileComponent, PictureComponent,
    PlaneComponent,
    ShellingComponent,
    ShipComponent, SignalComponent,
    StopComponent, TerroristComponent,
    TrainComponent,
} from "components/Marker/RenderMarker/Events/Events";

export const EVENTS_TYPES: IEventType[] = [
    {
        type: 'explosion',
        value: 'explosion',
        name: 'Explosion',
        component: ExplosionComponent,
        hasImage: true,
    }, {

        type: 'attention',
        value: 'attention',
        name: 'Attention',
        component: AttentionComponent,
        hasImage: true,
    }, {

        type: 'airDefense',
        value: 'airDefense',
        name: 'Air Defense',
        component: AirDefenseComponent,
        hasImage:true
    }, {

        type: 'shelling',
        value: 'shelling',
        name: 'Shelling',
        component: ShellingComponent,
        hasImage: true,
    }, {
        type: 'fight',
        value: 'fight',
        name: 'Fight',
        component: FightComponent,
        hasImage: true,
    }, {
        type: 'flag',
        value: 'flag',
        name: 'Flag',
        component: FlagComponent,
        hasImage: true,
    },
    {
        type: 'casualties',
        value: 'casualties',
        name: 'Casualties',
        component: CasualtiesComponent,
        hasImage: true,
    }, {
        type: 'stop',
        value: 'stop',
        name: 'Stop',
        component: StopComponent
    }, {
        type: 'electricity',
        value: 'electricity',
        name: 'Electricity',
        component: ElectricityComponent,
        hasImage: true,
    }, {
        type: 'camp',
        value: 'camp',
        name: 'Camp',
        component: CampComponent
    }, {
        type: 'fire',
        value: 'fire',
        name: 'Fire',
        component: FireComponent,
        hasImage: true,
    }, {
        type: 'manPads',
        value: 'manPads',
        name: 'ManPads',
        component: ManPadsComponent
    }, {
        type: 'train',
        value: 'train',
        name: 'Train',
        component: TrainComponent
    }, {
        type: 'microphone',
        value: 'microphone',
        name: 'Microphone',
        component: MicrophoneComponent
    }, {
        type: 'plane',
        value: 'plane',
        name: 'Plane',
        component: PlaneComponent,
        hasImage: true,
    }, {
        type: 'mines',
        value: 'mines',
        name: 'Mines',
        component: MinesComponent,
        hasImage: true,
    }, {
        type: 'missile',
        value: 'missile',
        name: 'Missile',
        component: MissileComponent,
        hasImage: true,
    }, {
        type: 'chemical',
        value: 'chemical',
        name: 'Chemical',
        component: ChemicalComponent
    }, {
        type: 'arrest',
        value: 'arrest',
        name: 'Arrest',
        component: ArrestComponent,
        hasImage: true,
    }, {
        type: 'drone',
        value: 'drone',
        name: 'Drone',
        component: DroneComponent,
        hasImage: true,
    }, {
        type: 'car',
        value: 'car',
        name: 'Car',
        component: CarComponent
    }, {
        type: 'bus',
        value: 'bus',
        name: 'Bus',
        component: BusComponent
    }, {
        type: 'ship',
        value: 'ship',
        name: 'Ship',
        component: ShipComponent,
        hasImage: true,
    }, {
        type: 'terrorist',
        value: 'terrorist',
        name: 'Terrorist',
        component: TerroristComponent
    }, {
        type: 'health',
        value: 'health',
        name: 'Health',
        component: HealthComponent
    }, {
        type: 'signal',
        value: 'signal',
        name: 'Signal',
        component: SignalComponent
    },{
        type: 'picture',
        value: 'picture',
        name: 'Picture',
        component: PictureComponent,
        hasImage: true,
    },{
        type: 'authority',
        value: 'authority',
        name: 'Authority',
        component: AuthorityComponent
    },
]
