import {
    AirDefense,
    Arrest,
    Attention,
    Authority,
    Bus,
    Camp,
    Car,
    Casualties,
    Chemical, Drone, Electricity, EventsShip,
    Explosion,
    Fight, Fire,
    Flag, Health, ManPads, Microphone, Mines, Missile, Picture, Plane,
    Shelling, Signal,
    Stop, Terrorist, Train
} from "assets/icons/icons";
import React from "react";
import { IColor, UnitTypes } from "core/types";
import { getColorByCountry } from "utils";

export interface IEventsProps {
    id: number
    icon: string | UnitTypes,
    imageClasses?: string,
    color?: IColor,
}

export interface IEventProps {
    id?: string | number,
    imageClasses?: string,
    color?: IColor,
}

export const Events = React.memo(({id, icon, imageClasses, color}: IEventsProps) => {
    if (icon === 'fight') {
        return <FightComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'flag') {
        return <FlagComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'explosion') {
        return <ExplosionComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'attention') {
        return <AttentionComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'airDefense') {
        return <AirDefenseComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'shelling') {
        return <ShellingComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'casualties') {
        return <CasualtiesComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'stop') {
        return <StopComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'electricity') {
        return <ElectricityComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'camp') {
        return <CampComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'fire') {
        return <FireComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'manPads') {
        return <ManPadsComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'train') {
        return <TrainComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'microphone') {
        return <MicrophoneComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'plane') {
        return <PlaneComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'mines') {
        return <MinesComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'missile') {
        return <MissileComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }

    if (icon === 'chemical') {
        return <ChemicalComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'arrest') {
        return <ArrestComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'drone') {
        return <DroneComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'car') {
        return <CarComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'bus') {
        return <BusComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'ship') {
        return <ShipComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'terrorist') {
        return <TerroristComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'health') {
        return <HealthComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'signal') {
        return <SignalComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'picture') {
        return <PictureComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    if (icon === 'authority') {
        return <AuthorityComponent
            id={id}
            imageClasses={imageClasses}
            color={color}/>
    }
    return <></>
})

export const FightComponent = ({imageClasses, color = getColorByCountry('default'), id = 'fight'}: IEventProps) => {
    return <div className={imageClasses}><Fight color={color} id={id}/></div>
}

export const FlagComponent = ({imageClasses, color = getColorByCountry('default'), id = 'flag'}: IEventProps) => {
    return <div className={imageClasses}><Flag color={color} id={id}/></div>
}

export const ExplosionComponent = ({
                                       imageClasses,
                                       color = getColorByCountry('default'),
                                       id = 'explosion'
                                   }: IEventProps) => {
    return <div className={imageClasses}><Explosion color={color} id={id}/></div>
}

export const AttentionComponent = ({
                                       imageClasses,
                                       color = getColorByCountry('default'),
                                       id = 'attention'
                                   }: IEventProps) => {
    return <div className={imageClasses}><Attention color={color} id={id}/></div>
}

export const AirDefenseComponent = ({
                                        imageClasses,
                                        color = getColorByCountry('default'),
                                        id = 'airDefense'
                                    }: IEventProps) => {
    return <div className={imageClasses}><AirDefense color={color} id={id}/></div>
}

export const ShellingComponent = ({
                                      imageClasses,
                                      color = getColorByCountry('default'),
                                      id = 'shelling'
                                  }: IEventProps) => {
    return <div className={imageClasses}><Shelling color={color} id={id}/></div>
}

export const CasualtiesComponent = ({
                                        imageClasses,
                                        color = getColorByCountry('default'),
                                        id = 'casualties'
                                    }: IEventProps) => {
    return <div className={imageClasses}><Casualties color={color} id={id}/></div>
}

export const StopComponent = ({imageClasses, color = getColorByCountry('default'), id = 'stop'}: IEventProps) => {
    return <div className={imageClasses}><Stop color={color} id={id}/></div>
}
export const ElectricityComponent = ({
                                         imageClasses,
                                         color = getColorByCountry('default'),
                                         id = 'electricity'
                                     }: IEventProps) => {
    return <div className={imageClasses}><Electricity color={color} id={id}/></div>
}
export const CampComponent = ({
                                  imageClasses,
                                  color = getColorByCountry('default'),
                                  id = 'camp'
                              }: IEventProps) => {
    return <div className={imageClasses}><Camp color={color} id={id}/></div>
}
export const FireComponent = ({
                                  imageClasses,
                                  color = getColorByCountry('default'),
                                  id = 'fire'
                              }: IEventProps) => {
    return <div className={imageClasses}><Fire color={color} id={id}/></div>
}
export const ManPadsComponent = ({
                                     imageClasses,
                                     color = getColorByCountry('default'),
                                     id = 'manPads'
                                 }: IEventProps) => {
    return <div className={imageClasses}><ManPads color={color} id={id}/></div>
}
export const TrainComponent = ({
                                   imageClasses,
                                   color = getColorByCountry('default'),
                                   id = 'train'
                               }: IEventProps) => {
    return <div className={imageClasses}><Train color={color} id={id}/></div>
}
export const MicrophoneComponent = ({
                                        imageClasses,
                                        color = getColorByCountry('default'),
                                        id = 'microphone'
                                    }: IEventProps) => {
    return <div className={imageClasses}><Microphone color={color} id={id}/></div>
}
export const PlaneComponent = ({
                                   imageClasses,
                                   color = getColorByCountry('default'),
                                   id = 'plane'
                               }: IEventProps) => {
    return <div className={imageClasses}><Plane color={color} id={id}/></div>
}
export const MinesComponent = ({
                                   imageClasses,
                                   color = getColorByCountry('default'),
                                   id = 'mines'
                               }: IEventProps) => {
    return <div className={imageClasses}><Mines color={color} id={id}/></div>
}
export const MissileComponent = ({
                                     imageClasses,
                                     color = getColorByCountry('default'),
                                     id = 'missiles'
                                 }: IEventProps) => {
    return <div className={imageClasses}><Missile color={color} id={id}/></div>
}
export const ChemicalComponent = ({
                                      imageClasses,
                                      color = getColorByCountry('default'),
                                      id = 'chemical'
                                  }: IEventProps) => {
    return <div className={imageClasses}><Chemical color={color} id={id}/></div>
}

export const ArrestComponent = ({
                                    imageClasses,
                                    color = getColorByCountry('default'),
                                    id = 'arrest'
                                }: IEventProps) => {
    return <div className={imageClasses}><Arrest color={color} id={id}/></div>
}
export const DroneComponent = ({
                                   imageClasses,
                                   color = getColorByCountry('default'),
                                   id = 'drone'
                               }: IEventProps) => {
    return <div className={imageClasses}><Drone color={color} id={id}/></div>
}
export const CarComponent = ({
                                 imageClasses,
                                 color = getColorByCountry('default'),
                                 id = 'car'
                             }: IEventProps) => {
    return <div className={imageClasses}><Car color={color} id={id}/></div>
}
export const BusComponent = ({
                                 imageClasses,
                                 color = getColorByCountry('default'),
                                 id = 'bus'
                             }: IEventProps) => {
    return <div className={imageClasses}><Bus color={color} id={id}/></div>
}
export const ShipComponent = ({
                                  imageClasses,
                                  color = getColorByCountry('default'),
                                  id = 'ship'
                              }: IEventProps) => {
    return <div className={imageClasses}><EventsShip color={color} id={id}/></div>
}
export const TerroristComponent = ({
                                       imageClasses,
                                       color = getColorByCountry('default'),
                                       id = 'terrorist'
                                   }: IEventProps) => {
    return <div className={imageClasses}><Terrorist color={color} id={id}/></div>
}
export const HealthComponent = ({
                                    imageClasses,
                                    color = getColorByCountry('default'),
                                    id = 'health'
                                }: IEventProps) => {
    return <div className={imageClasses}><Health color={color} id={id}/></div>
}
export const SignalComponent = ({
                                    imageClasses,
                                    color = getColorByCountry('default'),
                                    id = 'signal'
                                }: IEventProps) => {
    return <div className={imageClasses}><Signal color={color} id={id}/></div>
}
export const PictureComponent = ({
                                     imageClasses,
                                     color = getColorByCountry('default'),
                                     id = 'picture'
                                 }: IEventProps) => {
    return <div className={imageClasses}><Picture color={color} id={id}/></div>
}
export const AuthorityComponent = ({
                                       imageClasses,
                                       color = getColorByCountry('default'),
                                       id = 'authority'
                                   }: IEventProps) => {
    return <div className={imageClasses}><Authority color={color} id={id}/></div>
}


