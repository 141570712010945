import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Layer, Source, Popup as PopupMapBox } from "@urbica/react-map-gl";
import { MapboxGeoJSONFeature, MapMouseEvent } from "mapbox-gl";
import { IMarker } from "core/types";
import { getMarkerZoomView } from "utils";

export interface IUnitsProps {
    sourceId: string,
    data: IMarker[]
    onMarkerClick: (marker: IMarker | null) => void;
}

export const MarkerLayers = observer(({
                                          sourceId,
                                          data,
                                          onMarkerClick,
                                      }: IUnitsProps) => {
    const [hoveredLayer, setHoveredLayer] = useState<MapboxGeoJSONFeature | null>(null);
    const visibleZoom = useMemo(() => getMarkerZoomView('unit', undefined), [])

    const getData = useCallback((data: IMarker[]) => {
        return {
            'type': 'FeatureCollection',
            'features': data.map(unit => {
                return {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [unit.coordinates.lng, unit.coordinates.lat]
                    },
                    'properties': {
                        ...unit,
                        'icon-size': unit.type === 11 ? 0.08 : 0.16
                    }
                }
            })
        }
    }, [])

    const hoverData = useMemo(() => hoveredLayer ? ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [JSON.parse(hoveredLayer.properties?.coordinates).lng, JSON.parse(hoveredLayer.properties?.coordinates).lat]
        }
    }) : null, [hoveredLayer]);

    const handleLayerClick = useCallback((event: MapMouseEvent & { features?: MapboxGeoJSONFeature[] }) => {
        event.originalEvent.stopPropagation();
        const feature = event.features?.[0];
        if (feature) {
            onMarkerClick(feature.properties as IMarker);
        }
    }, [onMarkerClick]);

    return (
        <>
            <Source id={`hover${sourceId}`} type='geojson' data={hoverData}/>
            {hoveredLayer && <>
              <Layer
                id={`hover${sourceId}`}
                type='circle'
                source={`hover${sourceId}`}
                before={sourceId}
                filter={['all', ['>=', ['zoom'], visibleZoom]]}
                paint={{
                    'circle-radius': 26,
                    'circle-opacity': 1,
                    'circle-color': '#ffc600'
                }}
              />
              <PopupMapBox
                anchor="bottom"
                longitude={JSON.parse(hoveredLayer.properties?.coordinates).lng}
                latitude={JSON.parse(hoveredLayer.properties?.coordinates).lat}
                maxWidth='920px'
                closeButton={false}
                closeOnClick={false}
              >
                <div>{hoveredLayer.properties?.title}</div>
              </PopupMapBox>
            </>}
            <Source id={sourceId} type='geojson' data={getData(data)} cluster={false}
                    clusterRadius={0}
                    generateId={true}/>
            <div>
                <Layer
                    id={sourceId}
                    type='symbol'
                    source={sourceId}
                    onClick={handleLayerClick}
                    onEnter={(e: any) => setHoveredLayer(e.features[0])}
                    onLeave={() => setHoveredLayer(null)}
                    filter={['all', ['>=', ['zoom'], visibleZoom]]}
                    layout={{
                        'icon-image': ['get', 'icon'],
                        'icon-size': ['get', 'icon-size']
                    }}
                />
            </div>
        </>
    )
})
