import { makeObservable, observable, runInAction } from 'mobx';
import { IMarker } from "core/types";
import { tableStore } from "./TableStore";

export class MarkersStore {
    markers: IMarker[];
    events: IMarker[];
    sideBarEvents: IMarker[];
    units: IMarker[];
    eventsLayer: IMarker[];

    constructor() {
        this.markers = []
        this.events = []
        this.sideBarEvents = []
        this.units = []
        this.eventsLayer = []
        makeObservable(this, {
            markers: observable,
            events: observable,
            sideBarEvents: observable,
            units: observable,
            eventsLayer: observable,
        });
        this.addMarkerToStore = this.addMarkerToStore.bind(this)
    }

    setMarkers(data: IMarker[]) {
        runInAction(() => {
            this.markers = data.filter(marker => {
                if ((marker.type === 11 && marker.country === 1) || marker.icon === "airport"
                    || marker.icon === "uaMechanizedBrigade"
                    || marker.icon === "lightInfantryBrigade"
                    || marker.icon === "uaAviationBrigade"
                    || marker.icon === "uaArtilleryBrigade"
                    || marker.icon === "uaTankBrigade"
                    || marker.icon === "uaAntiAircraftMissileRegiment"
                    || marker.icon === "uaSpecialForcesBattalion"
                    || marker.icon === "uaMechanizedBattalion"
                    || marker.icon === "uaMotorizedBrigade"
                    || marker.icon === "uaAirAssaultBrigade"
                    || marker.icon === "uaAirMobileBrigade"
                    || marker.icon === "uaInfantryBrigade"
                    || marker.icon === "uaMarineBrigade"
                    || marker.icon === "uaMixedBrigade"
                    || marker.icon === "uaTankBattalion"
                    || marker.icon === 'uaAntiAircraftMissileBrigade'
                    || marker.icon === 'uaAssaultBrigade'
                    || marker.icon === 'uaInfantryBattalion'
                    || marker.icon === 'uaJagerBrigade'
                    || marker.icon === 'uaOperationalMotorizedBrigade'
                    || marker.icon === 'uaSpecialPoliceForce'
                    || marker.icon === 'uaSpecialPoliceForceBattalion'
                    || marker.icon === 'uaIntelligenceBattalions'
                    || marker.icon === 'uaSpecialPoliceForceRegiment'
                    || marker.icon === 'uaAirAssaultBattalion'
                    || marker.icon === 'uaMixedRegiment'
                    || marker.icon === 'uaRocketArtilleryBrigade'
                    || marker.icon === 'uaSpecialForcesRegiment'
                    || marker.icon === 'uaMixedBattalion'
                    || marker.icon === 'uaAirborneBattalion'
                    || marker.icon === 'uaSpecialForces'
                    || marker.icon === 'uaInfantryRegiment'
                    || marker.icon === 'uaMarineBattalion'
                    || marker.icon === 'uaIntelligenceBattalion'
                    || marker.icon === 'uaNavalSpecialForces'
                    || marker.icon === 'uaRapidResponseBrigade'
                    || marker.icon === 'uaVolunteerArmy'
                ) return undefined
                return marker
            });
            this.units = data.filter(marker => {
                return marker.icon === "airport"
                    || marker.icon === "uaMechanizedBrigade"
                    || marker.icon === "lightInfantryBrigade"
                    || marker.icon === "uaAviationBrigade"
                    || marker.icon === "uaArtilleryBrigade"
                    || marker.icon === "uaTankBrigade"
                    || marker.icon === "uaAntiAircraftMissileRegiment"
                    || marker.icon === "uaSpecialForcesBattalion"
                    || marker.icon === "uaMechanizedBattalion"
                    || marker.icon === "uaMotorizedBrigade"
                    || marker.icon === "uaAirAssaultBrigade"
                    || marker.icon === "uaAirMobileBrigade"
                    || marker.icon === "uaInfantryBrigade"
                    || marker.icon === "uaMarineBrigade"
                    || marker.icon === "uaMixedBrigade"
                    || marker.icon === "uaTankBattalion"
                    || marker.icon === 'uaAntiAircraftMissileBrigade'
                    || marker.icon === 'uaAssaultBrigade'
                    || marker.icon === 'uaInfantryBattalion'
                    || marker.icon === 'uaJagerBrigade'
                    || marker.icon === 'uaOperationalMotorizedBrigade'
                    || marker.icon === 'uaSpecialPoliceForce'
                    || marker.icon === 'uaSpecialPoliceForceBattalion'
                    || marker.icon === 'uaIntelligenceBattalions'
                    || marker.icon === 'uaSpecialPoliceForceRegiment'
                    || marker.icon === 'uaAirAssaultBattalion'
                    || marker.icon === 'uaMixedRegiment'
                    || marker.icon === 'uaRocketArtilleryBrigade'
                    || marker.icon === 'uaSpecialForcesRegiment'
                    || marker.icon === 'uaMixedBattalion'
                    || marker.icon === 'uaAirborneBattalion'
                    || marker.icon === 'uaSpecialForces'
                    || marker.icon === 'uaInfantryRegiment'
                    || marker.icon === 'uaMarineBattalion'
                    || marker.icon === 'uaIntelligenceBattalion'
                    || marker.icon === 'uaNavalSpecialForces'
                    || marker.icon === 'uaRapidResponseBrigade'
                    || marker.icon === 'uaVolunteerArmy'
            });
        })
    }

    setEvents(data: IMarker[]) {
        runInAction(() => {
            this.events = data.filter(event => {
                if (event.country === 1 && (event.icon === "explosion"
                    || event.icon === "shelling"
                    || event.icon === "fight"
                    || event.icon === "airDefense"
                    || event.icon === "electricity"
                    || event.icon === "fire"
                    || event.icon === "drone"
                    || event.icon === "attention"
                    || event.icon === "casualties"
                    || event.icon === "plane"
                    || event.icon === "mines"
                    || event.icon === "arrest"
                    || event.icon === "picture"
                    || event.icon === "missile"
                    || event.icon === "flag"
                    || event.icon === "ship"
                )) return undefined
                return event
            })
            this.eventsLayer = data.filter(event => {
                return (event.country === 1 && (event.icon === "explosion"
                    || event.icon === "shelling"
                    || event.icon === "fight"
                    || event.icon === "airDefense"
                    || event.icon === "electricity"
                    || event.icon === "fire"
                    || event.icon === "drone"
                    || event.icon === "attention"
                    || event.icon === "casualties"
                    || event.icon === "plane"
                    || event.icon === "mines"
                    || event.icon === "arrest"
                    || event.icon === "picture"
                    || event.icon === "missile"
                    || event.icon === "flag"
                    || event.icon === "ship"
                ))
            })

            this.sideBarEvents = data;
        })
    }

    public addMarkerToStore(marker: IMarker) {
        const {markerTypesData} = tableStore;
        const eventsMarkerTypeId = markerTypesData.find(type => type.title === 'Events')?.id
        runInAction(() => {
            if (marker.type === eventsMarkerTypeId) {
                if (marker.country === 1 && (marker.icon === "explosion"
                    || marker.icon === "shelling"
                    || marker.icon === "fight"
                    || marker.icon === "airDefense"
                    || marker.icon === "electricity"
                    || marker.icon === "fire"
                    || marker.icon === "drone"
                    || marker.icon === "attention"
                    || marker.icon === "casualties"
                    || marker.icon === "plane"
                    || marker.icon === "mines"
                    || marker.icon === "arrest"
                    || marker.icon === "picture"
                    || marker.icon === "missile"
                    || marker.icon === "flag"
                    || marker.icon === "ship"
                )) {
                    this.eventsLayer.push(marker)
                } else this.events.push(marker)
                this.sideBarEvents.push(marker)
            }
            if (marker.type !== eventsMarkerTypeId) {
                if ((marker.type === 11 && marker.country === 1) || marker.icon === "airport"
                    || marker.icon === "uaMechanizedBrigade"
                    || marker.icon === "lightInfantryBrigade"
                    || marker.icon === "uaAviationBrigade"
                    || marker.icon === "uaArtilleryBrigade"
                    || marker.icon === "uaTankBrigade"
                    || marker.icon === "uaAntiAircraftMissileRegiment"
                    || marker.icon === "uaSpecialForcesBattalion"
                    || marker.icon === "uaMechanizedBattalion"
                    || marker.icon === "uaMotorizedBrigade"
                    || marker.icon === "uaAirAssaultBrigade"
                    || marker.icon === "uaAirMobileBrigade"
                    || marker.icon === "uaInfantryBrigade"
                    || marker.icon === "uaMarineBrigade"
                    || marker.icon === "uaMixedBrigade"
                    || marker.icon === "uaTankBattalion"
                    || marker.icon === 'uaAntiAircraftMissileBrigade'
                    || marker.icon === 'uaAssaultBrigade'
                    || marker.icon === 'uaInfantryBattalion'
                    || marker.icon === 'uaJagerBrigade'
                    || marker.icon === 'uaOperationalMotorizedBrigade'
                    || marker.icon === 'uaSpecialPoliceForce'
                    || marker.icon === 'uaSpecialPoliceForceBattalion'
                    || marker.icon === 'uaIntelligenceBattalions'
                    || marker.icon === 'uaSpecialPoliceForceRegiment'
                    || marker.icon === 'uaAirAssaultBattalion'
                    || marker.icon === 'uaMixedRegiment'
                    || marker.icon === 'uaRocketArtilleryBrigade'
                    || marker.icon === 'uaSpecialForcesRegiment'
                    || marker.icon === 'uaMixedBattalion'
                    || marker.icon === 'uaAirborneBattalion'
                    || marker.icon === 'uaSpecialForces'
                    || marker.icon === 'uaInfantryRegiment'
                    || marker.icon === 'uaMarineBattalion'
                    || marker.icon === 'uaIntelligenceBattalion'
                    || marker.icon === 'uaNavalSpecialForces'
                    || marker.icon === 'uaRapidResponseBrigade'
                    || marker.icon === 'uaVolunteerArmy'
                ) {
                    this.units.push(marker)
                } else {
                    this.markers.push(marker);
                }

            }
        })
    }

    public updateMarkersInStore(data: IMarker) {
        runInAction(() => {
            const marker: IMarker | undefined = this.markers.find(marker => marker.id === data.id)
            const event: IMarker | undefined = this.events.find(event => event.id === data.id)
            const eventLayer: IMarker | undefined = this.eventsLayer.find(eventLayer => eventLayer.id === data.id)
            const unit: IMarker | undefined = this.units.find(unit => unit.id === data.id)
            const sideBarEvent: IMarker | undefined = this.sideBarEvents.find(event => event.id === data.id)

            if (marker) marker.coordinates = data.coordinates;
            if (event) event.coordinates = data.coordinates;
            if (eventLayer) eventLayer.coordinates = data.coordinates;
            if (unit) unit.coordinates = data.coordinates;
            if (sideBarEvent) sideBarEvent.coordinates = data.coordinates;
        })
    }

    public removeMarkerFromStore(ids: number[] | string[]) {
        runInAction(() => {
            ids.forEach(id => {
                this.markers = this.markers.filter(marker => marker.id !== id)
                this.events = this.events.filter(event => event.id !== id)
                this.sideBarEvents = this.sideBarEvents.filter(event => event.id !== id)
                this.eventsLayer = this.eventsLayer.filter(eventLayer => eventLayer.id !== id)
                this.units = this.units.filter(unit => unit.id !== id)
            });
        })
    }
}

export const markersStore = new MarkersStore();
