import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { observer } from 'mobx-react';
import { computed } from "mobx";
import MapGL from '@urbica/react-map-gl';
import { Nullable } from "primereact/ts-helpers";
import moment from "moment";
import { mapStore, markersStore, areaStore, drawStore } from 'stores';
import { IFeatures } from "stores/AreaStore";
import { DEFAULT_DRAW_PROPS } from "stores/DrawStore";
import { IDistance, IMarker } from "core/types";
import { IDrawProps } from "core/draws";
import { actionTypes } from "core/modals";
import {
    PageLayout,
    CoordinatesMarker,
    TerritoryLayers,
    PopupContainer,
    DistanceMeasureContainer,
    MarkerContainer,
    MarkerLayers, Strongholds, DrawComponent
} from 'components';
import {
    getMarkerZoomView,
    getRights
} from "utils";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import RightContext from "contexts/AppRightsProvider";
import { mapGlContainerController } from "./MapGlContainer.controller";
import useDeviceType from "hooks/useDeviceType";
import { mapActionClickType } from "components/Modals/CreateMarkerModal/CreateMarkerModal";

export const MapGlContainer = observer(() => {

    const {rights} = useContext(RightContext);
    const deviceType = useDeviceType();
    const {mapProps, zoom} = mapStore;
    const {areas} = areaStore;
    const {drawProps, drawMode, drawData} = drawStore;
    const {units, eventsLayer} = markersStore;
    const mapRef = useRef<MapGL | null>(null);
    const [popupInfo, setPopupInfo] = useState<IMarker | undefined>(undefined);
    const [distances, setDistances] = useState<IDistance[]>([]);
    const [distancesCoords, setDistancesCoords] = useState<any[]>([]);
    const [isMove, setIsMove] = useState<boolean>(false);
    const [isCreateMarker, setIsCreateMarker] = useState<boolean>(false);
    const [isMoveMarker, setIsMoveMarker] = useState<boolean>(false);
    const [isCopyMarkerToCoordinates, setIsCopyMarkerToCoordinates] = useState<boolean>(false);
    const [markerData, setMarkerData] = useState<IMarker | undefined>(undefined);
    const [dates, setDates] = useState<Nullable<Date[]>>(
        [new Date(moment().startOf('day').toISOString()),
            new Date(moment().endOf('day').toISOString())]
    )
    const [isAdsBlockOpened, setIsAdsBlockOpened] = useState<boolean>(!getRights(rights, 'hideAds'));
    const [unitsLayerVisible, setUnitsLayerVisible] = useState<boolean>(false)
    const [coordinatesPoint, setCoordinatesPoint] = useState<{ lat: number, lng: number } | null>(null)
    const {markers, events} = markersStore;
    const markersData = useMemo(() => {
        return computed(() => markers.concat(events))
    }, [markers, events]).get()
    const eventsMarkerTypeId = mapGlContainerController.getEventsMarkerTypeId()
    const typeIds = useMemo(() => mapGlContainerController.getTypeIds(eventsMarkerTypeId), [eventsMarkerTypeId])
    const allIds = useMemo(() => areas.flatMap(obj => obj.geoJson.features.map(f => {
        if (f.type === "Feature" && f.geometry?.type !== 'Point' && f.id) return f.id
        return null
    })).filter(id => id), [areas])

    const setDraws = useCallback((features: IFeatures) => {
        mapGlContainerController.setDrawData(
            features.filter(feature => feature.id !== undefined && !allIds.includes(feature.id))
        );
    }, [allIds]);

    const createMarker = useCallback(() => {
        setIsCreateMarker(!isCreateMarker)
    }, [isCreateMarker])

    const handleOnModeChangeClicked = useCallback((mode: string, drawProperties: IDrawProps | undefined) => {
        if (drawMode === mode) {
            mapGlContainerController.setDrawMode('simple_select');
            if (drawProperties) mapGlContainerController.setDrawProps(DEFAULT_DRAW_PROPS);
        }

        if (drawMode !== mode) {
            mapGlContainerController.setDrawMode(mode);
            if (drawProperties) mapGlContainerController.setDrawProps(drawProperties);
        }

    }, [drawMode])

    const onMapClick = useCallback((e: any) => {

        if (e.target.getLayer('units')) {
            const features = e.target.queryRenderedFeatures(e.point, {layers: ['units']});
            if (features.length > 0) return
        }

        if (isCreateMarker) {
            mapStore.onMapClick({
                onClose: () => setIsCreateMarker(false),
                actionType: mapActionClickType.CREATE_MARKER as actionTypes
            })
            setIsCreateMarker(false)
            mapGlContainerController.setMarkerCoordinates(undefined)
            return;
        }

        if (isMoveMarker) {
            if (markerData) {
                mapGlContainerController.updateMarker(markerData).then(() => {
                    setIsMoveMarker(false)
                    setMarkerData(undefined)
                })
            }
            return;
        }

        if (isCopyMarkerToCoordinates) {
            if (markerData) {
                mapGlContainerController.copyMarkerToCoordinates(markerData).then(() => {
                    setIsCopyMarkerToCoordinates(false)
                    setMarkerData(undefined)
                })
            }
            return;
        }

        if (drawProps.distanceMode) {
            const _distances = distancesCoords;
            _distances.push([mapProps.lngLat.lat, mapProps.lngLat.lng])
            setDistancesCoords(_distances)
            return;
        }

        if (coordinatesPoint) {
            setCoordinatesPoint(null)
        } else {
            setCoordinatesPoint({lat: e.lngLat.lat, lng: e.lngLat.lng})
        }

        if (drawMode !== 'static') return
    }, [isCreateMarker, isCopyMarkerToCoordinates, drawProps.distanceMode, mapProps, distancesCoords, isMoveMarker, markerData, coordinatesPoint, drawMode])

    const onMarkerClick = useCallback((marker: IMarker | null | any) => {
        if (typeof marker?.coordinates == "string") {
            setPopupInfo({
                ...marker,
                coordinates: JSON.parse(marker.coordinates)
            })
        } else {
            setPopupInfo(marker)
        }
        setIsAdsBlockOpened(!getRights(rights, 'hideAds'))
    }, [rights])

    const onMapLoaded = useCallback(async (e: { target: any, type: string }) => {
        if (e) {
            mapRef.current = e.target;
            await mapGlContainerController.onMapLoaded(e)
        }
        setUnitsLayerVisible(true)
    }, [])

    const handleOnMouseMove = useCallback((event: any) => mapGlContainerController.handleOnMouseMove(event), [])

    useEffect(() => {
        mapGlContainerController.getMarkersByTypeIds(typeIds)
    }, [typeIds])

    useEffect(() => {
        mapGlContainerController.getEvents(dates, eventsMarkerTypeId)
    }, [dates, eventsMarkerTypeId])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsCreateMarker(false);
                setIsMoveMarker(false);
                setIsCopyMarkerToCoordinates(false)
                setMarkerData(undefined);
                mapGlContainerController.setDrawProps(DEFAULT_DRAW_PROPS)
                if (drawProps.editMapMode) {
                    if (drawProps.editMapMode) {
                        setDraws(drawData.features)
                    }
                }

                if (drawMode !== 'simple_select') {
                    mapGlContainerController.setDrawMode('simple_select')
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [drawData.features, drawMode, setDraws, drawProps.editMapMode]);

    return (
        <PageLayout
            coordinatesPoint={coordinatesPoint}
            setCoordinatesPoint={setCoordinatesPoint}
            isCreateMarker={isCreateMarker}
            handleOnModeChangeClicked={handleOnModeChangeClicked}
            createMarker={createMarker}
            typeIds={typeIds}
            eventsMarkerTypeId={eventsMarkerTypeId}
            dates={dates}
            setDates={setDates}
            onMarkerClick={onMarkerClick}
        >
            <MapGL
                ref={mapRef}
                longitude={mapProps.lng}
                latitude={mapProps.lat}
                zoom={zoom}
                style={{height: '100vh'}}
                mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
                accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                cursorStyle={isCreateMarker || isMoveMarker || isCopyMarkerToCoordinates ? 'crosshair' : 'auto'}
                onMousemove={handleOnMouseMove}
                onClick={onMapClick}
                onViewportChange={mapStore.onMapMove}
                onLoad={onMapLoaded}
            >
                <MarkerContainer
                    markersData={markersData}
                    isMove={isMove}
                    isCreateMarker={isCreateMarker}
                    deviceType={deviceType}
                    onMarkerClick={onMarkerClick}
                    setIsMove={setIsMove}
                />
                <PopupContainer
                    popupInfo={popupInfo}
                    isMove={isMove}
                    deviceType={deviceType}
                    isAdsBlockOpened={isAdsBlockOpened}
                    setPopupInfo={setPopupInfo}
                    setIsAdsBlockOpened={setIsAdsBlockOpened}
                    setIsMoveMarker={setIsMoveMarker}
                    setMarkerData={setMarkerData}
                    setIsCopyMarkerToCoordinates={setIsCopyMarkerToCoordinates}
                />
                {zoom > getMarkerZoomView('info', undefined) &&
                    distances.length > 0 &&
                  <DistanceMeasureContainer
                    distances={distances}
                    setDistances={setDistances}
                  />}
                <DrawComponent
                    distances={distances}
                    setDistances={setDistances}
                />
                <TerritoryLayers/>
                <Strongholds/>
                <CoordinatesMarker/>
                {unitsLayerVisible && <MarkerLayers
                  sourceId='units'
                  data={units}
                  onMarkerClick={onMarkerClick}
                />}
                {unitsLayerVisible && <MarkerLayers
                  sourceId='eventsLayer'
                  data={eventsLayer}
                  onMarkerClick={onMarkerClick}
                />}
            </MapGL>
        </PageLayout>
    )
})
